<template>
    <div class="home">
        <div class="level">
            <div class="level-left">
                <div class="level-item">
                    <h1 class="title is-4">{{ $t("Interface.Key.List.Title") }}</h1>
                </div>
            </div>
            <div class="level-right">
                <div class="level-item">
                    <router-link
                        :to="{ name: 'Key-Create' }"
                        class="button is-theme"
                    >
                        {{ $t("Interface.Key.List.AddButton") }}
                    </router-link>
                </div>
            </div>
        </div>
        <b-table
            :data="Keys"
            :striped="true"
            :mobile-cards="true"
            :paginated="true"
            :per-page="ItemsPerPage"
            :current-page.sync="PageNumber"
            :total="KeysAmount"
            :loading="IsLoading"
            default-sort="keyId"
            icon-pack="fa"
            detailed
            detail-key="keyId"
        >
            <b-table-column v-slot="props" field="description" :label="$t('Interface.Key.Description')" sortable>
                {{ props.row.description }}
            </b-table-column>
            <b-table-column v-slot="props" field="expiration" :label="$t('Interface.Key.Expiration')" sortable>
                {{ filterDateTime(props.row.expiration) }}
            </b-table-column>
            <b-table-column v-slot="props" field="availableAmount" :label="$t('Interface.Key.List.AvailableAmount')" sortable>
                {{ props.row.availableAmount }}
            </b-table-column>
            <b-table-column v-slot="props" field="price" :label="$t('Interface.Key.Price')" sortable>
                {{ props.row.price }}
            </b-table-column>
            <b-table-column v-slot="props" field="currency" :label="$t('Interface.Key.Currency')" sortable>
                {{ props.row.currency }}
            </b-table-column>
            <b-table-column v-slot="props" field="maindoor" :label="$t('Interface.Key.MainDoor')">
                {{ findMainDoorName(props.row.doors) }}
            </b-table-column>
            <b-table-column v-slot="props" field="actions" :label="$t('Interface.DoorProvider.List.Actions')">
                <router-link
                    class="has-text-primary"
                    :to="{ name: 'Key-Share', params: { id: props.row.keyId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Share')" type="is-primary" animated>
                        <font-awesome-icon :icon="['fas', 'share-alt-square']" size="lg" />
                    </b-tooltip>
                </router-link>
                <router-link
                    class="has-text-warning"
                    style="margin-left:0.5rem;"
                    :to="{ name: 'Key-Invites', params: { id: props.row.keyId } }"
                >
                    <b-tooltip :label="$t('Interface.Key.Invites.Title')" type="is-warning" animated>
                        <span class="fa-stack">
                            <font-awesome-icon :icon="['fas', 'square']" class="fa-stack-2x is-warning" />
                            <font-awesome-icon :icon="['fas', 'user-clock']" class="fa-stack-1x fa-inverse" />
                        </span>
                    </b-tooltip>
                </router-link>
                <router-link
                    class="has-text-info"
                    style="margin-left:0.5rem;"
                    :to="{ name: 'Key-Edit', params: { id: props.row.keyId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Edit')" type="is-info" animated>
                        <font-awesome-icon :icon="['fas', 'pen-square']" size="lg" class="is-info" />
                    </b-tooltip>
                </router-link>
                <router-link
                    class="has-text-danger"
                    style="margin-left:0.5rem;"
                    :to="{ name: 'Key-Delete', params: { id: props.row.keyId } }"
                >
                    <b-tooltip :label="$t('Interface.Button.Delete')" type="is-danger" animated>
                        <font-awesome-icon :icon="['fas', 'times-circle']" size="lg" class="is-danger" />
                    </b-tooltip>
                </router-link>
            </b-table-column>

            <template slot="detail" slot-scope="props">
                <article class="media">
                    <div class="media-content">
                        <div class="content">
                            <strong>
                                {{ props.row.description }} [{{ props.row.keyId }}]
                            </strong>
                            <br>
                            <small v-if="props.row.hidden" class="has-text-danger">
                                {{ $t("Interface.Key.List.Hidden") }}
                                <br>
                            </small>
                            <br>
                            <dl>
                                <dt>{{ $t("Interface.Key.Expiration") }}:</dt>
                                <dd>{{ filterDateTime(props.row.expiration) }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Key.List.AvailableAmount") }}:</dt>
                                <dd>{{ props.row.availableAmount }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Key.Price") }}:</dt>
                                <dd>{{ props.row.price }} {{ props.row.currency }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Key.DurationSeconds") }}:</dt>
                                <dd>
                                    {{
                                        parseInt(props.row.durationSeconds, 10) / parseInt((props.row.multiplicator !== "0")
                                            ? props.row.multiplicator
                                            : "60", 10)
                                    }}
                                    <template v-if="parseInt(props.row.multiplicator, 10) === 86400">
                                        {{ $t("Interface.Key.Duration.Days") }}
                                    </template>
                                    <template v-else-if="parseInt(props.row.multiplicator, 10) === 3600">
                                        {{ $t("Interface.Key.Duration.Hours") }}
                                    </template>
                                    <template v-else>
                                        {{ $t("Interface.Key.Duration.Minutes") }}
                                    </template>
                                </dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Key.List.OpensLimit") }}:</dt>
                                <dd>{{ props.row.opensLimit }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Key.List.OpenDistance") }}:</dt>
                                <dd>{{ props.row.openDistance }}</dd>
                            </dl>
                            <dl>
                                <dt>{{ $t("Interface.Key.MainDoor") }}:</dt>
                                <dd>{{ findMainDoorName(props.row.doors) }}</dd>
                            </dl>
                        </div>
                    </div>
                </article>
            </template>

            <template slot="empty">
                <section class="section">
                    <div class="content has-text-grey has-text-centered">
                        <p>{{ $t("Interface.Door.List.Empty") }}</p>
                    </div>
                </section>
            </template>
        </b-table>
    </div>
</template>

<script>
import Helpers from "@/utils/Helpers"
import LoadingState from "@/mixins/LoadingState"
import {
    KEY_LIST_REQUEST
} from "@/store/actions/key"

export default {
    name: "KeyList",
    mixins: [LoadingState],
    data() {
        return {
            PageNumber: 1,
            ItemsPerPage: 25
        }
    },
    computed: {
        KeysAmount() {
            return this.$store.getters.KEYS_AMOUNT
        },
        Keys() {
            return this.$store.state.Key.List
        }
    },
    mounted() {
        if (!this.$store.getters.IS_KEYS_LOADED) {
            this.loadKeys()
        }
    },
    methods: {
        loadKeys() {
            this.switchLoading()
            this.$store
                .dispatch(KEY_LIST_REQUEST)
                .finally(() => this.switchLoading())
        },
        filterDateTime(date) {
            return Helpers.filterDateTime(date)
        },
        findMainDoorName(doors) {
            const MainDoor = doors.find((x) => x.main === true)
            return (MainDoor) ? MainDoor.name : ""
        }
    },
    metaInfo() {
        return {
            title: this.$t("Interface.Key.List.Title")
        }
    }
}
</script>

<style scoped>
    dl {
        width: 100%;
        overflow: hidden;
        padding: 0;
        margin: 0 !important;
        padding: 0.25rem 0;
    }
    dt {
        float: left;
        width: 25%;
        padding: 0;
        margin: 0
    }
    dd {
        float: left;
        width: 75%;
        padding: 0;
        margin: 0
    }
    dl:nth-child(2n+1) {
        background-color: #fefefe;
    }
</style>
